import { render, staticRenderFns } from "./CourseCard.vue?vue&type=template&id=76f0d314"
import script from "./CourseCard.vue?vue&type=script&lang=js"
export * from "./CourseCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports